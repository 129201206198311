import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (this.distance > 0) {
      setInterval(this.updateCountDown, 250)
    }
    this.updateCountDown()
  }

  updateCountDown = () => {
    requestAnimationFrame(() => {
      const days = Math.floor(this.distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((this.distance % (1000 * 60)) / 1000)

      // Display the result in the element with id="demo"
      this.element.textContent =
        'Dans ' + days + 'j ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
    })
  }

  get endDate() {
    return this.data.get('endDate')
  }

  get distance() {
    const now = new Date().getTime()
    return this.countDownDate - now
  }

  get countDownDate() {
    return new Date(this.endDate).getTime()
  }
}
