import { Controller } from '@hotwired/stimulus'

import { swalVisitorType, teacherSubscriptionUpSale } from '../templates/sweet_alerts'

export default class extends Controller {
  static values = { title: String, text: String, confirmButton: String, footer: String }

  visitorType(e) {
    e.preventDefault()
    swalVisitorType(e, false)
  }

  visitorTypeFromMobileApp(e) {
    e.preventDefault()
    swalVisitorType(e, true)
  }

  teacherSubscriptionUpSale(e) {
    e.preventDefault()
    teacherSubscriptionUpSale(e, true, this.title, this.text, this.confirmButton, this.footer)
  }

  // Getters

  get title() {
    return this.titleValue
  }

  get text() {
    return this.textValue
  }

  get confirmButton() {
    return this.confirmButtonValue
  }

  get footer() {
    return this.footerValue
  }
}
