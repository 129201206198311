import { Controller } from '@hotwired/stimulus'
import { Crisp } from 'crisp-sdk-web'
import { useMeta } from 'stimulus-use'

// Connects to data-controller="crisp"
export default class extends Controller {
  static metaNames = [
    'user_prefix_id',
    'first_name',
    'last_name',
    'premium',
    'email',
    'user_account_type',
    'locale'
  ]

  static values = {
    enable: { type: Boolean, default: false }
  }

  initialize() {
    if (this.isPreview) return

    useMeta(this)

    if (!window.$crisp) {
      Crisp.configure('02d1060a-6c4b-41ff-9028-ae397d9e5fd4', {
        locale: this.localeMeta,
        tokenId: this.userPrefixIdMeta,
        autoload: this.enableValue
      })
      Crisp.chat.onChatOpened(this.handleOpened.bind(this))
      Crisp.session.onLoaded(this.handleSessionLoaded.bind(this))
    }
  }

  connect() {
    if (this.isPreview) return

    this.toggleCrisp()
  }

  hideCrisp() {
    Crisp.setVacationMode(true)
    Crisp.muteSound(true)
    this.chatElement?.classList?.add('hidden')
  }

  showCrisp() {
    Crisp.setVacationMode(false)
    Crisp.muteSound(false)
    this.chatElement?.classList?.remove('hidden')
  }

  handleOpened() {
    Crisp.user.setEmail(this.emailMeta)
    Crisp.user.setNickname(`${this.firstNameMeta} ${this.lastNameMeta}`)
    Crisp.session.setData({
      user_id: this.userPrefixIdMeta,
      premium: this.premiumMeta,
      account_type: this.userAccountTypeMeta
    })
  }

  handleSessionLoaded() {
    this.toggleCrisp()
    this.setAsPermanent()
    Crisp.setZIndex(1000) // we don't put the z-index too high as we want to display the toast ABOVE Crisp
  }

  toggleCrisp() {
    if (this.enableValue) {
      this.showCrisp()
    } else {
      this.hideCrisp()
    }
  }

  setAsPermanent() {
    const client = document.querySelector('.crisp-client')
    client.id = 'crisp-client'
    client.setAttribute('data-turbo-permanent', 'true')
  }

  // getters

  get chatElement() {
    return document.querySelector('#crisp-client')
  }
}
